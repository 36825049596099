import React from "react"
import Layout from "../components/layout"

const NotFoundPage = ({ location }) => (
  <Layout title="404: Page introuvable" hideBlocks={true} location={location}>
    <div className="uk-text-center">
      <p>404: Page introuvable</p>
    </div>
  </Layout>
)

export default NotFoundPage
